<template>
  <be-modal
    :id="modalId"
    :title="$t('components.documents.document_row.send_for_signatures')"
    :size="useCustomTitles ? 'xl' : 'lg'"
    @ok="handleOk"
    @hidden="resetForm"
  >
    <template #footer="{ ok, cancel }">
      <be-button variant="light" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button
        v-be-tooltip="{
          title: disabledOkButtonTooltip,
          disabled: !disableOk,
        }"
        variant="primary"
        :disabled="disableOk"
        :loading="sending"
        icon="fa-envelope"
        @click="ok"
      >
        {{ okTitle }}
      </be-button>
    </template>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="col-shrink"></th>

            <th class="">
              {{ $t("activerecord.attributes.document.filename") }}
            </th>

            <th class="col-shrink">
              {{ $t("activerecord.attributes.document.created_at") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="col-shrink text-muted text-center">
              <i :class="doc.fontawesome_icon" />
            </td>

            <td class="">
              <document-link
                :document-id="doc.id"
                :filename="doc.filename"
                :show-icon="false"
                :title="doc.title"
              />
            </td>

            <td class="col-shrink">
              {{ doc.created_at ? $d(new Date(doc.created_at), "date") : "" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <be-form-group
      v-if="allowChangeOfSignatureSolutions"
      :label="$t('models.document.signature_solutions')"
      :label-for="`${modalId}-signature-solutions`"
    >
      <be-form-checkbox-group
        :id="`${modalId}-signature-solutions`"
        v-model="selectedSignatureSolutions"
        :options="allowedSignatureSolutions"
        :disabled="allowedSignatureSolutions.length == 1"
        class="mb-1"
      />
    </be-form-group>

    <be-form-group
      :label="$t('components.documents.document_row.message')"
      :label-for="`${modalId}-signature-message`"
    >
      <be-form-textarea
        :id="`${modalId}-signature-message`"
        v-model="message"
        rows="3"
        max-rows="20"
      />
    </be-form-group>

    <h3 v-if="externalSignatoriesEnabledByUser">
      {{ $t("components.documents.signature_form.internal_signatories") }}
    </h3>

    <be-table
      :items="availableUsers"
      :fields="userFields"
      class="mb-3"
      @row-clicked="(item) => toggleUserSelect(item)"
    >
      <template #selected="{ item }">
        <be-form-checkbox
          :id="`user-${item.id}`"
          :checked="selectedUserIds.includes(item.id)"
          :disabled="disableChangeOfSignatories"
          @change="toggleUserSelect(item)"
        />
      </template>

      <template #avatar="{ item }">
        <user-avatar :user="item" />
      </template>

      <template #name="{ item }">
        <div>
          {{ item.name }}
        </div>

        <membership-subtitle :user-id="item.id" class="small" />
      </template>

      <template #title="{ item }">
        <be-form-group
          :label="translateAttribute('membership', 'role')"
          :label-for="`custom-title-${item.id}`"
          label-sr-only
        >
          <be-form-input
            :id="`custom-title-${item.id}`"
            v-model="customTitles[item.id]"
            @keydown.space.stop
          />
        </be-form-group>
      </template>

      <template #email="{ item }">
        {{ item.email }}
      </template>
    </be-table>

    <template v-if="!disableChangeOfSignatories">
      <be-button
        variant="outline-secondary"
        size="sm"
        class="mb-3"
        @click="onToggleAllButton"
      >
        {{ toggleAllButtonTitle }}
      </be-button>
    </template>

    <template v-if="!disableChangeOfSignatories">
      <be-form-checkbox
        :id="`${modalId}-inform-all-signatories`"
        v-model="informAllSignatories"
        class="mb-3"
      >
        {{ $t("components.documents.signature_form.inform_all_signatories") }}
        <i
          v-be-tooltip="
            $t(
              'components.documents.signature_form.inform_all_signatories_tooltip'
            )
          "
          class="fal fa-question-circle ml-1"
        />
      </be-form-checkbox>
    </template>

    <be-form-checkbox
      v-if="!disableExternalSignatures"
      :id="`${modalId}-external-signatories`"
      v-model="externalSignatoriesEnabledByUser"
      :description="externalSignatoriesDescription"
      class="mb-3"
    >
      {{ $t("components.documents.signature_form.allow_external_signatories") }}
    </be-form-checkbox>

    <div v-if="!disableExternalSignatures && externalSignatoriesEnabledByUser">
      <h3>
        {{ $t("components.documents.signature_form.external_signatories") }}
      </h3>

      <be-table-simple hover class="mb-2 external-signatories-table">
        <thead>
          <tr>
            <th>
              {{ translateAttribute("user", "name") }}
            </th>

            <th v-if="useCustomTitles">
              {{ translateAttribute("membership", "role") }}
            </th>

            <th>
              {{ translateAttribute("user", "email") }}
            </th>

            <th class="col-shrink" />
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="externalSignatory in externalSignatories"
            :key="externalSignatory.id"
          >
            <td class="align-top">
              <be-form-input
                v-model="externalSignatory.name"
                :placeholder="translateAttribute('user', 'name')"
              />
            </td>

            <td v-if="useCustomTitles">
              <be-form-group
                :label="translateAttribute('membership', 'role')"
                :label-for="`custom-title-${externalSignatory.id}`"
                label-sr-only
              >
                <be-form-input
                  :id="`custom-external-title-${externalSignatory.id}`"
                  v-model="externalSignatory.title"
                  :placeholder="translateAttribute('membership', 'role')"
                  @keydown.space.stop
                />
              </be-form-group>
            </td>

            <td class="align-top">
              <be-form-group class="mb-0">
                <be-form-input
                  v-model="externalSignatory.email"
                  type="email"
                  :placeholder="translateAttribute('user', 'email')"
                  trim
                  :state="validateEmail(externalSignatory.email)"
                  debounce="500"
                />

                <be-form-invalid-feedback
                  v-if="validateEmail(externalSignatory.email) === false"
                  :state="validateEmail(externalSignatory.email)"
                >
                  {{ $t("components.documents.signature_form.invalid_email") }}
                </be-form-invalid-feedback>
              </be-form-group>
            </td>

            <td class="col-shrink align-middle">
              <be-button
                v-be-tooltip="$t('buttons.titles.remove')"
                size="sm"
                variant="danger"
                icon="fa-times"
                inline
                @click="removeExternalSignatory(externalSignatory)"
              />
            </td>
          </tr>

          <tr v-if="externalSignatories.length === 0">
            <td colspan="3">
              <be-alert variant="info" class="mb-0">
                {{
                  $t(
                    "components.documents.signature_form.add_external_signatory_prompt"
                  )
                }}
              </be-alert>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td colspan="3">
              <be-button
                variant="outline-secondary"
                size="sm"
                inline
                @click="addExternalSignatory"
              >
                {{
                  $t(
                    "components.documents.signature_form.add_external_signatory"
                  )
                }}
              </be-button>
            </td>
          </tr>

          <tr>
            <td colspan="3">
              <be-form-checkbox
                v-if="externalSignatoriesEnabledByUser"
                :id="`${modalId}-external-access`"
                v-model="externalAccess"
              >
                {{ $t("components.documents.signature_form.external_access") }}
              </be-form-checkbox>
            </td>
          </tr>
        </tfoot>
      </be-table-simple>
    </div>

    <slot />

    <be-alert v-if="selectedUserNames.length > 0" variant="info">
      <div>
        {{ $t("components.documents.signature_form.will_be_sent_to") }}
      </div>

      <div>
        {{ selectedUserNames.join(", ") }}
      </div>
    </be-alert>
  </be-modal>
</template>

<script>
import TextUtilities from "@/mixins/textUtilities";

import MembershipSubtitle from "@/components/shared/MembershipSubtitle.vue";

export default {
  components: {
    MembershipSubtitle,
  },

  mixins: [TextUtilities],

  props: {
    doc: {
      required: true,
      type: Object,
    },

    availableUsers: {
      required: false,
      type: Array,
      default: () => [],
    },

    disableChangeOfSignatureSolutions: {
      required: false,
      type: Boolean,
      default: false,
    },

    disableChangeOfSignatories: {
      required: false,
      type: Boolean,
      default: false,
    },

    disableExternalSignatures: {
      type: Boolean,
      required: false,
      default: false,
    },

    useCustomTitles: {
      type: Boolean,
      required: false,
      default: false,
    },

    defaultTitles: {
      type: Object,
      required: false,
      default: () => {},
    },

    submitUrl: {
      type: String,
      required: false,
      default: null,
    },

    preselectedUserIds: {
      type: Array,
      required: false,
      default: null,
    },
  },

  emits: ["document-updated", "reset"],

  data() {
    return {
      userFields: [
        { key: "selected", label: "", class: "col-shrink" },
        { key: "avatar", label: "", class: "col-shrink" },

        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        this.useCustomTitles
          ? {
              key: "title",
              label: this.translateAttribute("membership", "role"),
            }
          : null,
        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },
      ].filter((field) => field),

      externalSignatoriesFields: [
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        {
          key: "email",
          label: this.translateAttribute("user", "email"),
        },
        { key: "remove", label: "", class: "col-shrink" },
      ],

      externalSignatories: [
        {
          tempId: this.generateUuid(),
          name: "",
          email: "",
          title: "",
        },
      ],

      informAllSignatories: false,
      message: "",
      selectedSignatureSolutions: [],
      selectedUserIds: [],
      sending: false,
      modalId: `signature-form-${this.generateUuid()}`,
      externalSignatoriesEnabledByUser: false,
      externalAccess: false,
      customTitles: { ...this.defaultTitles },
    };
  },

  computed: {
    selectedUserNames() {
      const names = this.availableUsers
        .filter((user) => this.selectedUserIds.includes(user.id))
        .map((user) => user.name);

      if (this.externalSignatoriesEnabledByUser) {
        this.externalSignatories.forEach((externalSignatory) => {
          if (externalSignatory.name.length > 0 && externalSignatory.email) {
            names.push(externalSignatory.name);
          }
        });
      }

      return names;
    },

    externalSignatoriesDescription() {
      if (this.doc.owner_type === "AnnualReport") {
        return this.$t(
          "components.annual_reports.upload_tab.external_auditor_forbidden"
        );
      }
      return null;
    },

    invalidExternalSignatories() {
      return this.externalSignatories.filter((externalSignatory) => {
        // Ignore empty rows
        if (
          externalSignatory.name.length === 0 &&
          externalSignatory.email.length === 0
        ) {
          return false;
        }

        return !(
          externalSignatory.name.length > 0 &&
          externalSignatory.email.length > 0 &&
          externalSignatory.email.includes("@") &&
          !externalSignatory.email.includes(";")
        );
      });
    },

    allowChangeOfSignatureSolutions() {
      return (
        this.$currentCompany.allow_change_of_signature_solutions &&
        !this.disableChangeOfSignatureSolutions
      );
    },

    allowedSignatureSolutions() {
      const solutions = this.$currentCompany.allowed_signature_solutions || [];
      if (solutions.length === 0) {
        solutions.push("electronicid");
      }
      return solutions.map((signatureType) => {
        return {
          value: signatureType,

          text: this.$t(
            `documents.signatures.sign_options.${signatureType}.title`
          ),
        };
      });
    },

    okTitle() {
      if (this.sending) {
        return this.$i18n.t("buttons.states.sending");
      }

      return this.$i18n.t("buttons.titles.send");
    },

    disableOk() {
      if (this.sending) {
        return true;
      }

      return this.noSignatureSolutions || this.noSignatories;
    },

    disabledOkButtonTooltip() {
      if (this.noSignatureSolutions) {
        return this.$t(
          "components.documents.signature_form.no_signature_solutions"
        );
      }

      if (this.noSignatories) {
        return this.$t(
          "components.documents.signature_form.not_enough_signatories"
        );
      }

      return null;
    },

    noSignatureSolutions() {
      return (
        this.$currentCompany.allow_change_of_signature_solutions &&
        this.selectedSignatureSolutions.length < 1
      );
    },

    noSignatories() {
      if (this.externalSignatoriesEnabledByUser) {
        if (this.selectedUserIds.length > 1) {
          return false;
        }

        return (
          this.externalSignatories.length === 0 ||
          this.externalSignatories.filter((s) => !s.name || !s.email).length > 0
        );
      } else {
        return this.selectedUserIds.length === 0;
      }
    },

    allSelected() {
      return this.selectedUserIds.length == this.availableUsers.length;
    },

    toggleAllButtonTitle() {
      return this.allSelected
        ? this.$t("buttons.toggle_all_selection.deselect_all")
        : this.$t("buttons.toggle_all_selection.select_all");
    },
  },

  watch: {
    doc(value) {
      if (value.id) {
        this.selectDefaultUsers();
        this.$beModal.show(this.modalId);
      } else {
        this.$beModal.hide(this.modalId);
      }
    },
  },

  created() {
    this.selectedSignatureSolutions = this.allowedSignatureSolutions.map(
      (solution) => solution.value
    );
  },

  methods: {
    addExternalSignatory() {
      this.externalSignatories = this.externalSignatories.concat({
        tempId: this.generateUuid(),
        name: "",
        email: "",
      });
    },

    removeExternalSignatory(externalSignatory) {
      this.externalSignatories = this.externalSignatories.filter(
        (existingExternalSignatory) =>
          existingExternalSignatory.tempId != externalSignatory.tempId
      );
    },

    // Needs to be method since company is not loaded for url in data()
    localUrl() {
      return this.submitUrl || this.url("documents/signatures");
    },

    membership(user) {
      return this.$currentCompany.memberships.find(
        (membership) => membership.user.id == user.id
      );
    },

    selectDefaultUsers() {
      this.externalSignatories = [
        {
          name: "",
          email: "",
          title: "",
        },
      ];
      if (this.preselectedUserIds !== null) {
        this.selectedUserIds = this.preselectedUserIds;
      } else {
        this.selectedUserIds = this.availableUsers
          .filter((user) =>
            ["chairman", "regular", "alternate"].includes(
              this.membership(user)?.function
            )
          )
          .map((user) => user.id);
      }
    },

    resetForm() {
      this.message = "";
      this.selectDefaultUsers();
      this.selectedSignatureSolutions = this.allowedSignatureSolutions.map(
        (solution) => solution.value
      );
      this.externalSignatoriesEnabledByUser = false;
      this.externalAccess = false;
      this.informAllSignatories = false;
      this.$emit("reset");
    },

    onToggleAllButton() {
      if (this.allSelected) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    },

    selectAll() {
      const userIds = this.availableUsers.map((user) => user.id);
      this.selectedUserIds = userIds;
    },

    toggleUserSelect(user) {
      if (!this.disableChangeOfSignatories) {
        if (this.selectedUserIds.includes(user.id)) {
          this.deselectUser(user.id);
        } else {
          this.selectUser(user.id);
        }
      }
    },

    deselectAll() {
      this.selectedUserIds = [];
    },

    deselectUser(userId) {
      const index = this.selectedUserIds.findIndex(
        (selectedUserId) => selectedUserId == userId
      );

      if (index > -1) {
        this.selectedUserIds.splice(index, 1);
      }
    },

    selectUser(userId) {
      if (!this.selectedUserIds.includes(userId)) {
        this.selectedUserIds.push(userId);
      }
    },

    handleOk(beModalEvt) {
      // Prevent modal from closing
      beModalEvt.preventDefault();
      this.submit();
    },

    async submit() {
      const params = {
        signature_case: {
          document_id: this.doc.id,
          user_ids: this.selectedUserIds,

          external_signatories: this.externalSignatoriesEnabledByUser
            ? this.externalSignatories
            : [],

          allowed_signature_types: this.selectedSignatureSolutions,
          message: this.message,

          inform_all_signatories: this.informAllSignatories,

          external_access:
            this.externalAccess && this.externalSignatoriesEnabledByUser,
        },
      };

      if (this.useCustomTitles) {
        params.signature_case.custom_titles = this.customTitles;
      }

      try {
        this.sending = true;
        const { data } = await axios.post(this.localUrl(), params);
        this.$emit("document-updated", data);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.sending = false;
        this.$nextTick(() => {
          this.$beModal.hide(this.modalId);
        });
      }
    },
  },
};
</script>
